<template>
  <div class="tab-bar">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab-bar__tab', { 'tab-bar__tab--active': index === activeTab }]"
      @click="selectTab(index)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  methods: {
    selectTab(index) {
      this.$emit("tab-clicked", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-bar {
  @apply px-3 py-2 border-ls-gray-200 border-b flex;

  &__tab {
    @apply rounded-lg px-3 py-2 cursor-pointer text-xs font-semibold w-full text-center;
    padding: 10px 20px;

    &--active {
      @apply bg-ls-purple text-white;
      border: 1px solid #6f81e0;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
