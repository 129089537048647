<template>
  <div class="hour-modal">
    <Modal
      :show-close-button="false"
      @close="onClose"
      :heading="$t('pages.editor.hourModal.title')"
    >
      <div class="hour-modal__content" slot="modal-content">
        <div class="hour-modal__list">
          <div
            class="hour-modal__option"
            :class="{
              'hour-modal__option--active': hour.active,
              'hour-modal__option--active-start': isStart(index),
              'hour-modal__option--active-middle': isMiddle(index),
              'hour-modal__option--active-end': isEnd(index)
            }"
            v-for="(hour, index) in hours"
            :key="index"
            @click="hour.active = !hour.active"
          >
            <span v-if="index !== 0">{{ index }}</span>
            <span v-if="index === 0">12</span>
          </div>
        </div>
        <Button class="hour-modal__update-btn" @click="onClose">
          {{ $t("pages.editor.hourModal.update") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";


export default {
  components: {
    Modal,
    Button
  },
  props: {
    hours: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({}),
  methods: {
    onClose() {
      this.$emit("close");
    },
    isStart(index) {
      if (
        this.hours[index].active &&
        ((this.hours[index - 1] && !this.hours[index - 1].active) ||
          index === 0) &&
        this.hours[index + 1] &&
        this.hours[index + 1].active
      ) {
        return true;
      }
      return false;
    },
    isMiddle(index) {
      if (
        this.hours[index].active &&
        this.hours[index - 1] &&
        this.hours[index - 1].active &&
        this.hours[index + 1] &&
        this.hours[index + 1].active
      ) {
        return true;
      }
      return false;
    },
    isEnd(index) {
      if (
        this.hours[index].active &&
        this.hours[index - 1] &&
        this.hours[index - 1].active &&
        ((this.hours[index + 1] && !this.hours[index + 1].active) ||
          index === 23)
      ) {
        return true;
      }
    }
  }
};
</script>

<style lang="scss">
.hour-modal {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: flex;
    margin-top: 1.5rem;
  }

  &__option {
    font-size: 14px;
    padding: 8px 5px;
    text-align: center;
    cursor: pointer;

    &--active {
      background-color: #000;
      color: #fff;
      border-radius: 8px;
    }

    &--active-start {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--active-middle {
      border-radius: 0;
    }

    &--active-end {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__update-btn {
    @apply mt-4;
  }
}
</style>
