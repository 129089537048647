<template>
  <div class="date-modal">
    <Modal
      :show-close-button="false"
      @close="onClose"
      :heading="$t('pages.editor.dates.title')"
    >
      <div class="date-modal__content" slot="modal-content">
        <div class="date-modal__field">
          <label class="date-modal__label">{{
            $t("pages.editor.dates.start")
          }}</label>
          <input
            class="date-modal__input"
            type="date"
            v-model="tempDates.start"
          />
        </div>
        <div class="date-modal__field">
          <label class="date-modal__label">{{
            $t("pages.editor.dates.end")
          }}</label>
          <input
            class="date-modal__input"
            type="date"
            v-model="tempDates.end"
          />
        </div>
        <div class="date-modal__feedback" v-if="feedback">
          {{ feedback }}
        </div>
        <Button
          theme="secondary"
          class="date-modal__cancel-btn"
          @click="onClose"
        >
          {{ $t("pages.editor.dates.cancel") }}
        </Button>
        <Button class="date-modal__update-btn" @click="onConfirm">
          {{ $t("pages.editor.dates.update") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";

export default {
  components: {
    Modal,
    Button
  },
  props: {
    dates: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    tempDates: {},
    feedback: ""
  }),
  mounted() {
    this.tempDates.start = this.dates.start;
    this.tempDates.end = this.dates.end;
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      if (this.tempDates.start && this.tempDates.end) {
        this.$emit("confirm", this.tempDates);
      } else {
        this.feedback = this.$t("pages.editor.dates.feedback");
      }
    }
  }
};
</script>

<style lang="scss">
.date-modal {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @apply mt-6;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full text-sm;
    line-height: 20px;
    min-height: 44px;
  }

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__cancel-btn {
    @apply mt-4;
  }

  &__update-btn {
    @apply mt-2;
  }

  &__feedback {
    @apply text-ls-red mt-1 text-xs font-semibold;
  }
}
</style>
