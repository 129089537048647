<template>
  <div class="side-bar">
    <DropDown
      class="side-bar__status"
      v-model="slide.status"
      :list="statusOptions"
    >
      <template slot="selected">{{ $t(`dropdown.${slide.status}`) }}</template>
    </DropDown>
    <div class="side-bar__tags">
      <div class="side-bar__field">
        <div
          class="side-bar__input"
          @click="openTagModal"
          v-if="tagsWithNames.length === 0"
        >
          <inline-svg
            class="side-bar__tag-icon"
            :src="require(`../../assets/icons/Screen.svg`)"
            aria-label="Plus"
          ></inline-svg>
          <span>{{ $t("pages.editor.slide.allScreensSelected") }}</span>
        </div>
        <!-- <Button
          class="side-bar__tag-btn"
          theme="secondary"
          @click="openTagModal"
          v-if="tagsWithNames.length === 0"
        >
          {{ $t("pages.editor.slide.addTagsBtn") }}
          <inline-svg
            class="side-bar__tag-icon"
            :src="require(`../../assets/icons/Plus.svg`)"
            aria-label="Plus"
          ></inline-svg>
        </Button> -->

        <div class="side-bar__tags" v-else>
          <div class="side-bar__tags-wrapper" @click="openTagModal">
            <Tag
              v-for="tag in visibleTags"
              :key="tag.id"
              :tag="tag"
              :active="true"
              :showIcon="true"
              class="side-bar__tag"
              :deleted="tag.deleted"
            />
            <Tag
              v-if="additionalTagsCount > 0"
              :tag="{ name: `+${additionalTagsCount}` }"
              :active="true"
              :showIcon="false"
              class="side-bar__tag side-bar__tag--additional"
            />
            <div class="side-bar__tags__add-tag">
              <inline-svg
                class="side-bar__tag-icon"
                :src="require(`../../assets/icons/Plus small.svg`)"
                aria-label="Plus"
              ></inline-svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-bar__field">
      <label class="side-bar__label">{{
        $t("pages.editor.slide.branches")
      }}</label>
      <div class="side-bar__input" @click="openBranchModal">
        <span v-if="allBranchesActive">{{
          $t("pages.editor.slide.allBranchesSelected")
        }}</span>
        <span v-else>{{ activeBranches }} Teams</span>
        <inline-svg
          class="dropdown__icon"
          :src="require(`../../assets/icons/Down.svg`)"
          aria-label="Settings"
        ></inline-svg>
      </div>
    </div>

    <div class="side-bar__divider"></div>

    <ValidationObserver>
      <form class="side-bar__form">
        <div class="side-bar__field-row">
          <ValidationProvider
            class="side-bar__field side-bar__field--half"
            name="Duration"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="side-bar__label" for="Duration">{{
              $t("pages.editor.slide.duration")
            }}</label>
            <div class="side-bar__input-wrapper">
              <input
                class="side-bar__input side-bar__input--number"
                v-model.number="slide.duration"
                type="number"
              />
              <span class="side-bar__input-suffix">sec</span>
            </div>
            <span class="side-bar__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="side-bar__field side-bar__field--half">
            <label class="side-bar__label">{{
              $t("pages.editor.slide.frequency")
            }}</label>
            <DropDown
              v-model="slide.frequency"
              :list="frequencyList"
              variant="subtle"
            >
              <template slot="selected">
                <div class="side-bar__chosen-frequency">
                  {{ $t(`dropdown.${slide.frequency}`) }}
                </div>
              </template>
            </DropDown>
          </div>
        </div>

        <div class="side-bar__divider"></div>

        <div class="side-bar__field">
          <label class="side-bar__label">{{
            $t("pages.editor.slide.schedule")
          }}</label>
          <div
            class="side-bar__input side-bar__input__schedule"
            @click="openDateModal"
          >
            <div class="side-bar__input__schedule__dates">
              <span v-if="slide.dates?.start">{{ slide.dates.start }}</span>
              <span v-if="slide.dates?.end"> - {{ slide.dates.end }}</span>
            </div>
            <inline-svg
              class="side-bar__tag-icon"
              :src="require(`../../assets/icons/Cal.svg`)"
              aria-label="Calendar"
            ></inline-svg>
          </div>
        </div>

        <div class="side-bar__field-row">
          <div class="side-bar__field side-bar__field--half">
            <div class="side-bar__input" @click="openDayModal">
              <div class="side-bar__days" v-if="days.length > 0">
                <div
                  class="side-bar__day"
                  v-for="(day, index) in days"
                  :key="index"
                >
                  <div v-if="day !== '-'">
                    <span>{{ $t(`pages.editor.days.${day}`) }}</span>
                    <span v-if="days[index + 1] && days[index + 1] !== '-'"
                      >,</span
                    >
                  </div>
                  <span v-if="day === '-'"> - </span>
                </div>
              </div>
              <inline-svg
                class="dropdown__icon"
                :src="require(`../../assets/icons/Down.svg`)"
                aria-label="Settings"
              ></inline-svg>
            </div>
          </div>

          <div class="side-bar__field side-bar__field--half">
            <div class="side-bar__input" @click="openHourModal">
              <div class="side-bar__hours" v-if="hours.length > 0">
                <div
                  class="side-bar__hour"
                  v-for="(hour, index) in hours"
                  :key="index"
                >
                  <div v-if="hour !== '-'">
                    <span
                      v-if="
                        ((hours[index + 1] && hours[index + 1] !== '-') ||
                          (!hours[index + 1] && hours[index - 1] !== ',')) &&
                          hours[index - 1]
                      "
                      >{{ getPreciseHour(hour) }}</span
                    >
                    <span v-else>{{ getHour(hour) }}</span>
                    <span v-if="hour > 11">{{
                      $t("pages.editor.hours.pm")
                    }}</span
                    ><span v-if="hour < 12">{{
                      $t("pages.editor.hours.am")
                    }}</span>
                    <span v-if="hours[index + 1] && hours[index + 1] !== '-'"
                      >,</span
                    >
                  </div>
                  <span v-if="hour === '-'"> - </span>
                </div>
              </div>
              <inline-svg
                class="side-bar__tag-icon"
                :src="require(`../../assets/icons/Clock.svg`)"
                aria-label="Clock"
              ></inline-svg>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="side-bar__slide-infos" v-if="!newSlide">
      <p class="side-bar__slide-info">
        {{ new Date(slide.originalDate).toLocaleString() }}<br />
        <span class="side-bar__slide-info__creator"
          >{{ getName(slide.createdBy) }}
          {{ $t("pages.editor.slide.createdBy") }}</span
        >
      </p>
      <p class="side-bar__slide-info">
        {{ new Date(slide.lastChangedDate).toLocaleString() }}
        <br /><span class="side-bar__slide-info__creator"
          >{{ getName(slide.editedBy) }}
          {{ $t("pages.editor.slide.changedBy") }}</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import DropDown from "@/components/DropDown";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

extend("required", required);

export default {
  components: {
    Tag,
    DropDown,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    slideTags: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    frequencyList: [0, 1, 2, 4, 9],
    statusOptions: ["draft", "published", "archived"]
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    tagsWithNames() {
      if (
        this.tags &&
        Object.keys(this.tags).length !== 0 &&
        this.slideTags &&
        this.slideTags.length > 0
      ) {
        return this.slideTags
          .map(tag => {
            return { ...this.tags[tag.id] };
          })
          .map(tag => {
            if (!tag.name) {
              tag.name = this.$t("pages.editor.slide.deletedTag");
              tag.deleted = true;
              return tag;
            } else {
              return tag;
            }
          });
      }

      return [];
    },
    visibleTags() {
      return this.tagsWithNames.slice(0, 6);
    },
    additionalTagsCount() {
      return Math.max(0, this.tagsWithNames.length - 6);
    },
    newSlide() {
      return !this.$route.params.id;
    },
    days() {
      let dayString = [];
      this.slide.days?.map((day, index) => {
        if (day.active) {
          if (
            this.slide.days[index - 1] &&
            this.slide.days[index - 1].active &&
            this.slide.days[index + 1] &&
            this.slide.days[index + 1].active
          ) {
            dayString.push("-");
          } else {
            dayString.push(day.name);
          }
        }
      });

      return dayString.filter((i, index) => dayString[index - 1] !== i);
    },
    hours() {
      let hourString = [];
      this.slide.time?.map((hour, index) => {
        if (hour.active) {
          if (
            this.slide.time[index - 1] &&
            this.slide.time[index - 1].active &&
            this.slide.time[index + 1] &&
            this.slide.time[index + 1].active
          ) {
            hourString.push("-");
          } else {
            hourString.push(index);
          }
        }
      });
      return hourString.filter((i, index) => hourString[index - 1] !== i);
    },
    allBranchesActive() {
      if (this.slide.branches && this.slide.branches.length > 0) {
        let active = true;
        this.slide.branches.map(branch => {
          if (!branch.active) {
            active = false;
          }
        });

        return active;
      }
      return false;
    },
    activeBranches() {
      if (this.slide.branches && this.slide.branches.length > 0) {
        let active = 0;
        this.slide.branches.map(branch => {
          if (branch.active) {
            active++;
          }
        });

        return active;
      }

      return 0;
    }
  },
  methods: {
    getName(id) {
      if (this.team && this.team.users) {
        return this.team.users[id]?.name;
      }
      return "";
    },
    openTagModal() {
      this.$emit("open-add-tag-modal");
    },
    openDayModal() {
      this.$emit("open-day-modal");
    },
    openHourModal() {
      this.$emit("open-hour-modal");
    },
    openBranchModal() {
      this.$emit("open-branch-modal");
    },
    openDateModal() {
      this.$emit("open-date-modal");
    },
    getHour(hour) {
      if (this.user.language === "de") {
        return hour;
      }

      if (this.user.language === "en") {
        switch (hour) {
          case 0:
            return 12;
          case 1:
            return 1;
          case 2:
            return 2;
          case 3:
            return 3;
          case 4:
            return 4;
          case 5:
            return 5;
          case 6:
            return 6;
          case 7:
            return 7;
          case 8:
            return 8;
          case 9:
            return 9;
          case 10:
            return 10;
          case 11:
            return 11;
          case 12:
            return 12;
          case 13:
            return 1;
          case 14:
            return 2;
          case 15:
            return 3;
          case 16:
            return 4;
          case 17:
            return 5;
          case 18:
            return 6;
          case 19:
            return 7;
          case 20:
            return 8;
          case 21:
            return 9;
          case 22:
            return 10;
          case 23:
            return 11;
        }
      }
    },
    getPreciseHour(hour) {
      if (this.user.language === "de") {
        return `${hour}:59`;
      }

      if (this.user.language === "en") {
        switch (hour) {
          case 0:
            return "12:59";
          case 1:
            return "1:59";
          case 2:
            return "2:59";
          case 3:
            return "3:59";
          case 4:
            return "4:59";
          case 5:
            return "5:59";
          case 6:
            return "6:59";
          case 7:
            return "7:59";
          case 8:
            return "8:59";
          case 9:
            return "9:59";
          case 10:
            return "10:59";
          case 11:
            return "11:59";
          case 12:
            return "12:59";
          case 13:
            return "1:59";
          case 14:
            return "2:59";
          case 15:
            return "3:59";
          case 16:
            return "4:59";
          case 17:
            return "5:59";
          case 18:
            return "6:59";
          case 19:
            return "7:59";
          case 20:
            return "8:59";
          case 21:
            return "9:59";
          case 22:
            return "10:59";
          case 23:
            return "11:59";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.side-bar {
  @apply border-l border-ls-gray-200;
  position: fixed;
  right: 0;
  top: 53px;
  padding: 0px 16px 16px 16px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 2;
  overflow: auto;

  &__tag-btn {
    @apply mt-1 inline-flex;
  }
  &__tag-icon {
    @apply flex mt-1;
  }
  &__tags {
    &-wrapper {
      @apply bg-ls-gray-100 px-2 pb-2 pt-1 rounded-lg mb-1 flex flex-wrap;
      cursor: pointer;
    }
    &__add-tag {
      @apply mt-1 inline-block;
      height: 26px;
      width: 26px;
      border-radius: 26px;
      text-align: center;

      &:hover {
        @apply bg-ls-gray-200;
      }
    }
  }

  &__tag-icon {
    margin-top: 0;
    display: inline;
  }

  &__tag {
    @apply mt-1 mr-1;
  }

  &__form,
  &__field {
    display: flex;
    flex-direction: column;
  }

  &__field {
    @apply mt-4;
  }

  &__chosen-frequency {
    @apply text-sm whitespace-nowrap;
    max-width: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-2 cursor-pointer;
    font-size: 14px;
    line-height: 20px;
    min-height: 36px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    span {
      width: 100%;
    }
    &__schedule {
      &__dates {
        flex-grow: 1;
        span {
          width: auto;
        }
      }
    }
  }

  &__label,
  &__error {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__label {
    margin-bottom: 0.25rem;
  }

  &__days,
  &__hours {
    display: flex;
    flex-grow: 1;
  }

  &__hours {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__day,
  &__hour {
    margin-right: 2px;
  }

  &__divider {
    @apply bg-ls-gray-200 w-full;
    margin-top: 16px;
    height: 1px;
  }
  &__slide-infos {
    @apply border-gray-200 border-t;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 279px;
    background: white;
    padding: 16px;
  }
  &__slide-info {
    @apply text-xs font-semibold mb-2;
    &__creator {
      @apply text-ls-gray-600;
      font-weight: 400;
    }
  }

  &__field-row {
    display: flex;
    gap: 16px;
  }

  &__field {
    &--half {
      flex: 1;
      min-width: 0; // This helps with flex layout
    }
  }

  &__input-wrapper {
    @apply bg-ls-gray-100 rounded-lg flex items-center;
    padding-right: 8px;
  }

  &__input {
    &--number {
      @apply bg-transparent flex-grow;
      width: 100%;
      border: none;
      outline: none;
    }
  }

  &__input-suffix {
    @apply text-sm text-ls-gray-800 ml-2;
  }

  .side-bar__status {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .side-bar__status {
      margin-top: 16px;
      display: block;
      @apply text-xs font-semibold;
    }
  }
}
</style>
