<template>
  <div class="editor-widget">
    <div class="editor-widget-bar">
      <div class="editor-widget-bar__element" v-if="widgetData">
        <div class="editor-widget-bar__element-label">
          {{ $t("pages.editor.widget.label") }}
        </div>
        <div class="editor-widget-bar__options">
          <div
            class="editor-widget-bar__option"
            :class="{ 'editor-widget-bar__option--active': url === option.url }"
            v-for="(option, index) in widgetOptions"
            :key="index"
            @click="setWidgetUrl(option)"
          >
            <img
              class="editor-widget-bar__option-preview"
              :src="option.preview"
              :alt="option.name"
            />
            {{ option.name }}
          </div>
        </div>
        <Button
          :disabled="disableSaveButton"
          class="editor-widget-bar__button"
          @click="setWidgetUrl"
          v-if="$route.params.file === 'webview'"
        >
          {{ $t("pages.editor.widget.confirmBtn") }}
        </Button>
        <div class="editor-widget-bar__feedback" v-if="showEmptyUrlError">
          {{ $t("pages.editor.widget.feedback.empty") }}
        </div>
      </div>
    </div>
    <div class="editor-widget-content" v-if="slide.url">
      <div
        class="editor-widget-content__iframe-wrapper editor-widget-content__iframe-wrapper--horizontal"
        v-if="orientation === '0'"
      >
        <iframe
          ref="iFrame"
          class="editor-widget-content__iframe editor-widget-content__iframe--horizontal"
          :src="slide.url.landscape"
        />
      </div>
      <div
        class="editor-widget-content__iframe-wrapper editor-widget-content__iframe-wrapper--vertical"
        v-if="orientation === '1'"
      >
        <iframe
          ref="iFrame"
          class="editor-widget-content__iframe editor-widget-content__iframe--vertical"
          :src="slide.url.landscape"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import { mapGetters } from "vuex";

export default {
  components: {
    Button
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    showEmptyUrlError: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    chosenOption: "",
    url: "",
    feedback: "",
    disableSaveButton: false,
    showUrlPreview: false
  }),
  computed: {
    ...mapGetters("slides", {
      widgetData: "getWidgetData"
    }),
    widgetOptions() {
      if (this.widgetData) {
        return this.widgetData[this.$route.params.file].options;
      }
      return [];
    },
    newSlide() {
      return this.$route.name === "EditorNew";
    }
  },
  watch: {
    url() {
      if (this.slide.url) {
        this.disableSaveButton = this.slide.url.landscape === this.url;
      }
    },
    chosenOption() {
      this.url = this.chosenOption.url;
    },
    slide: {
      handler() {
        if (this.slide.url) {
          this.url = this.slide.url.landscape;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.setInitiationUrl();
  },
  methods: {
    setInitiationUrl() {
      if (this.slide.url) {
        this.url = this.slide.url.landscape;
        this.widgetData.options?.forEach(option => {
          if (option.url === this.url) {
            this.chosenOption = option;
          }
        });
      } else {
        this.url = this.widgetOptions[0].url;
        let url = {
          landscape: this.url,
          portrait: this.url
        };

        this.slide.url = url;
        this.slide.preview = this.widgetOptions[0].preview;
      }
    },
    async setWidgetUrl(option) {
      if (this.$route.params.file !== "webview") {
        this.url = option.url;
        if (this.slide.url) {
          this.slide.url.landscape = option.url;
          this.slide.url.portrait = option.url;
        } else {
          let tempUrl = {
            landscape: option.url,
            portrait: option.url
          };

          this.slide.url = tempUrl;
        }
        this.slide.preview = option.preview;
      } else {
        this.feedback = "";
        if (this.slide.url) {
          this.slide.url.landscape = this.url;
          this.slide.url.portrait = this.url;
          this.disableSaveButton = true;
        } else {
          let url = {
            landscape: this.url,
            portrait: this.url
          };

          this.slide.url = url;
          this.disableSaveButton = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.editor-widget-bar {
  position: fixed;
  left: 0;
  top: 53px;
  padding: 16px 12px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;

  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__option {
    @apply p-4 mb-3 flex items-center border border-ls-gray-400 rounded-lg cursor-pointer text-sm;

    &-preview {
      width: 68px;
      height: 38px;
      border-radius: 4px;
      margin-right: 8px;
    }

    &--active {
      @apply border-ls-purple;
    }
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    @apply w-full mt-3;
  }

  &__feedback {
    @apply mt-2 text-ls-red text-sm;
  }
}

.editor-widget-content {
  height: calc(100vh - 53px);
  padding-top: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__iframe-wrapper--horizontal {
    width: 768px;
    height: 432px;
  }

  &__iframe-wrapper--vertical {
    width: 324px;
    height: 576px;
  }

  &__iframe {
    &--horizontal {
      width: 1920px;
      height: 1080px;
      transform: scale(0.4);
      transform-origin: 0 0;
    }

    &--vertical {
      width: 1080px;
      height: 1920px;
      transform: scale(0.3);
      transform-origin: 0 0;
    }
  }
}
</style>
