<template>
  <div class="branch-modal">
    <Modal @close="onClose" :heading="$t('pages.editor.branches.title')">
      <div class="branch-modal__content" slot="modal-content">
        <div class="branch-modal__search">
          <inline-svg
            class="branch-modal__search-icon"
            :src="require('../../assets/icons/Search.svg')"
            aria-label="icon"
          ></inline-svg>
          <input
            class="branch-modal__search-field"
            type="text"
            v-model="searchTerm"
            :placeholder="$t('pages.editor.branches.placeholder')"
          />
        </div>
        <div class="branch-modal__list">
          <BranchOption
            class="branch-modal__option"
            v-for="(branch, index) in searchList"
            :key="index"
            :branch="branch"
          />
        </div>
        <div class="branch-modal__button-wrapper">
          <div class="branch-modal__button-selectors">
            <Button
              theme="secondary"
              class="branch-modal__selector-btn"
              @click="selectAll"
            >
              {{ $t("pages.editor.branches.selectAll") }}
            </Button>
            <Button
              theme="secondary"
              class="branch-modal__selector-btn"
              @click="selectNone"
            >
              {{ $t("pages.editor.branches.selectNone") }}
            </Button>
          </div>
          <Button class="branch-modal__update-btn" @click="onClose">
            {{ $t("pages.editor.branches.update") }}
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BranchOption from "@/components/BranchOption";
import Button from "@/components/Button";

export default {
  components: {
    Modal,
    BranchOption,
    Button
  },
  props: {
    branches: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchTerm: null
  }),
  computed: {
    searchList() {
      if (this.searchTerm) {
        return this.branches.filter(branch => {
          return branch.name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        });
      }

      return this.branches;
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    selectAll() {
      this.branches.map(branch => {
        branch.active = true;
      });
    },
    selectNone() {
      this.branches.map(branch => {
        branch.active = false;
      });
    }
  }
};
</script>

<style lang="scss">
.branch-modal {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__search {
    @apply w-full relative mt-6;

    &-icon {
      position: absolute;
      top: 11px;
      left: 10px;
    }

    &-field {
      @apply bg-ls-gray-100 rounded-lg pl-8 pr-3 py-2 w-full;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__list {
    @apply mt-4 border border-ls-gray-300 rounded;
    max-height: 400px;
    overflow-y: auto;
  }

  &__option {
    @apply p-4 border-b border-ls-gray-200;

    &:last-child {
      border: none;
    }
  }

  &__button-wrapper {
    @apply flex mt-4 justify-between items-center;
  }

  &__button-selectors {
    display: flex;
    justify-content: flex-start;
  }

  &__selector-btn {
    &:last-child {
      @apply ml-1;
    }
  }

  &__update-btn {
    min-width: 80px;
  }
}
</style>
