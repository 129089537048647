<template>
  <div class="editor-weather">
    <div class="editor-weather-bar">
      <div class="editor-weather-bar__element">
        <div class="editor-weather-bar__element-label">
          {{ $t("pages.editor.weather.locationLabel") }}
        </div>
        <input
          ref="autocompleteInput"
          class="editor-weather-bar__input"
          v-model="location"
        />

        <div class="editor-weather-bar__options">
          <div class="editor-weather-bar__element-label">
            {{ $t("pages.editor.weather.languageLabel") }}
          </div>
          <div class="editor-weather-bar__option">
            <DropDown
              class="editor-weather-bar__theme-dropdown"
              :list="languageOptions"
              v-model="language"
              :translation="false"
            >
              <template slot="selected">
                <div class="editor-weather-bar__theme-option">
                  {{ language }}
                </div>
              </template>
            </DropDown>
          </div>
          <div class="editor-weather-bar__element-label">
            {{ $t("pages.editor.weather.unitsLabel") }}
          </div>
          <div class="editor-weather-bar__option">
            <DropDown
              class="editor-weather-bar__theme-dropdown"
              :list="unitsOptions"
              v-model="unit"
              :translation="true"
            >
              <template slot="selected">
                <div class="editor-weather-bar__theme-option">
                  {{ $t(`dropdown.${unit}`) }}
                </div>
              </template>
            </DropDown>
          </div>
        </div>
      </div>
    </div>
    <div class="editor-weather-content" v-if="slide.url">
      <div
        class="editor-weather-content__iframe-wrapper editor-weather-content__iframe-wrapper--horizontal"
        v-if="orientation === '0'"
      >
        <iframe
          ref="iFrame"
          class="editor-weather-content__iframe editor-weather-content__iframe--horizontal"
          :src="slide.url.landscape"
        />
      </div>
      <div
        class="editor-weather-content__iframe-wrapper editor-weather-content__iframe-wrapper--vertical"
        v-if="orientation === '1'"
      >
        <iframe
          ref="iFrame"
          class="editor-weather-content__iframe editor-weather-content__iframe--vertical"
          :src="slide.url.landscape"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from "../../components/DropDown.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DropDown
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    url: "",
    location: "",
    feedback: "",
    language: "",
    unit: "",
    languageOptions: ["en", "de"],
    unitsOptions: ["metric", "imperial"],
    latitude: "",
    longitude: "",
    autocomplete: null
  }),
  computed: {
    ...mapGetters("slides", {
      widgetData: "getWidgetData"
    }),
    weatherData() {
      if (this.widgetData) {
        return this.widgetData[this.$route.params.file]?.options[0];
      }
      return "";
    }
  },
  watch: {
    slide: {
      handler() {
        if (this.slide.url) {
          this.url = this.slide.url.landscape;
          this.extractUrlParams();
        }
      },
      deep: true
    },
    weatherData() {
      this.extractUrlParams();
    },
    language() {
      this.updateUrl();
    },
    unit() {
      this.updateUrl();
    }
  },
  mounted() {
    this.setInitiationUrl();

    if (typeof google !== "undefined") {
      this.initializeAutocomplete();
    } else {
      window.addEventListener("load", this.initializeAutocomplete);
    }
  },
  methods: {
    ...mapActions("slides", ["fetchLocationData"]),
    initializeAutocomplete() {
      const input = this.$refs.autocompleteInput;
      const options = {};
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options);
      this.autocomplete.addListener("place_changed", this.handlePlaceChange);
    },
    handlePlaceChange() {
      const place = this.autocomplete.getPlace();
      let city = "";
      if (place && place.address_components) {
        place.address_components.forEach(component => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
        });
      }

      this.setLocation({
        name: city,
        long: place.geometry.location.lng(),
        lat: place.geometry.location.lat()
      });
    },
    setLocation(location) {
      if (location) {
        this.location = location.name;
        this.longitude = location.long;
        this.latitude = location.lat;
        this.updateUrl();
      }
    },
    setInitiationUrl() {
      if (this.slide.url) {
        this.url = this.slide.url.landscape;
        this.extractUrlParams();
      } else {
        this.url = this.weatherData.url;
        let url = {
          landscape: this.url,
          portrait: this.url
        };

        this.slide.url = url;
        this.slide.preview = this.weatherData.preview;
      }
    },
    extractUrlParams() {
      if (!this.weatherData.url) {
        return;
      }
      let tempUrl = this.url
        ? new URL(this.url)
        : new URL(this.weatherData.url);
      let params = tempUrl.searchParams;

      params.forEach((value, key) => {
        if (key === "lang") {
          this.language = value;
        }

        if (key === "units") {
          this.unit = value;
        }

        if (key === "latitude") {
          this.latitude = value;
        }

        if (key === "longitude") {
          this.longitude = value;
        }

        if (key === "name") {
          this.location = value;
        }

        this.slide.preview = this.weatherData.preview;
      });
    },
    updateUrl() {
      let url = new URL(this.weatherData.url);
      url.searchParams.set("lang", this.language);
      url.searchParams.set("units", this.unit);
      url.searchParams.set("latitude", this.latitude);
      url.searchParams.set("longitude", this.longitude);
      url.searchParams.set("name", this.location);

      this.url = url.toString();
      this.setWeatherUrl();
    },
    async setWeatherUrl() {
      this.feedback = "";
      if (this.slide.url) {
        this.slide.url.landscape = this.url;
        this.slide.url.portrait = this.url;
      } else {
        let url = {
          landscape: this.url,
          portrait: this.url
        };

        this.slide.url = url;
        this.slide.preview = this.weatherData.preview;
      }
    }
  }
};
</script>

<style lang="scss">
.editor-weather-bar {
  position: fixed;
  left: 0;
  top: 53px;
  padding: 16px 12px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;

  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full mb-3;
    font-size: 14px;
    line-height: 20px;
  }

  &__location-options {
    @apply mt-1 shadow border-gray-100 border p-2 rounded absolute bg-white z-50;
  }

  &__location-option {
    @apply cursor-pointer font-semibold text-sm text-ls-gray-800 p-2;

    &:hover {
      @apply bg-gray-100;
    }
  }

  &__option {
    @apply flex justify-between mb-3 text-sm w-full;
  }

  &__theme-dropdown {
    @apply w-full;
  }

  &__button {
    @apply w-full my-3;
  }

  &__feedback {
    @apply my-2 text-ls-red text-sm;
  }

  &__sign-up {
    @apply mt-6 text-sm;

    a {
      @apply underline text-ls-purple font-semibold;
    }
  }
}

.editor-weather-content {
  height: calc(100vh - 53px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__iframe-wrapper--horizontal {
    width: 768px;
    height: 432px;
  }

  &__iframe-wrapper--vertical {
    width: 324px;
    height: 576px;
  }

  &__iframe {
    &--horizontal {
      width: 1920px;
      height: 1080px;
      transform: scale(0.4);
      transform-origin: 0 0;
    }

    &--vertical {
      width: 1080px;
      height: 1920px;
      transform: scale(0.3);
      transform-origin: 0 0;
    }
  }
}
</style>
