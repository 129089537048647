<template>
  <div class="dropdown" :class="{ 'dropdown--subtle': variant === 'subtle' }">
    <div
      class="dropdown__selected-wrapper"
      :class="{
        'dropdown__selected-wrapper--no-border': noBorder,
        'dropdown__selected-wrapper--subtle': variant === 'subtle'
      }"
      @click="showDropdown = !showDropdown"
    >
      <slot name="selected"> </slot>
      <inline-svg
        class="dropdown__icon"
        :src="require(`../assets/icons/Down.svg`)"
        aria-label="Down"
      ></inline-svg>
    </div>

    <div 
      class="dropdown__wrapper" 
      v-if="showDropdown"
      :class="{ 'dropdown__wrapper--top': direction === 'top' }"
    >
      <div
        class="dropdown__option"
        @click="selectItem(index)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div v-if="itemValue">
          <span v-if="translation || item[itemValue] === 'allTags'">
            {{ $t(`dropdown.${item[itemValue]}`) }}</span
          >
          <span v-else>{{ item[itemValue] }}</span>
        </div>
        <div v-else>
          <span v-if="translation || item === 'allTags'">
            {{ $t(`dropdown.${item}`) }}</span
          >
          <span v-else>{{ item }}</span>
        </div>
      </div>
    </div>
    <div
      class="dropdown__backdrop"
      v-if="showDropdown"
      @click="showDropdown = false"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    list: {
      type: Array,
      default: () => []
    },
    translation: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: null
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'subtle'].includes(value)
    },
    direction: {
      type: String,
      default: 'bottom',
      validator: (value) => ['top', 'bottom'].includes(value)
    }
  },
  data: () => ({
    showDropdown: false
  }),
  methods: {
    selectItem(index) {
      this.$emit("input", this.list[index]);
      this.showDropdown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &--subtle {
    @apply w-full;
  }

  &__selected-wrapper {
    @apply flex items-center justify-between cursor-pointer px-2 py-2 border rounded-lg border-ls-gray-400;

    &:hover {
      @apply bg-ls-gray-100;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.02);
    }

    &--no-border {
      @apply border-none;
    }

    &--subtle {
      @apply bg-ls-gray-100 border-none;

      &:hover {
        @apply bg-ls-gray-100;
        box-shadow: none;
      }
    }
  }

  &__icon {
    @apply ml-2;
  }

  &__wrapper {
    @apply border-ls-gray-200 border p-2 rounded-lg bg-white absolute shadow right-0;
    top: 40px;
    right: 0;
    z-index: 5;

    &--top {
      top: auto;
      bottom: 40px;
    }
  }

  &__option {
    @apply text-ls-gray-800 font-semibold text-xs cursor-pointer mb-2;
    min-width: 120px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__backdrop {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>