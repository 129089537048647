<template>
  <div :class="['editor', activeTabClass, orientationClass]">
    <div class="editor__header">
      <Button
        theme="secondary"
        class="editor__close-btn"
        :disabled="isUploading"
        @click="onClose"
      >
        <inline-svg
          class="editor__close-btn__icon"
          :src="require(`../../assets/icons/X.svg`)"
          aria-label="Close"
        ></inline-svg>
        {{ $t("pages.editor.closeButton") }}
      </Button>
      <Button
        theme="secondary"
        class="editor__close-btn--mobile"
        :disabled="isUploading"
        @click="onClose"
        icon="X"
      >
      </Button>
      <div class="editor__title">
        <input
          :placeholder="$t('pages.editor.slide.title')"
          class="editor__input"
          v-model="slide.title"
          type="text"
        />
      </div>
      <div class="editor__header--right">
        <div class="editor__header-slide-menu" v-if="!newSlide">
          <inline-svg
            class="editor__header-slide-menu-icon"
            :src="require('../../assets/icons/More.svg')"
            aria-label="Menu Icon"
            @click="showSlideMenu = true"
          ></inline-svg>
          <div class="editor__header-slide-menu-wrapper" v-if="showSlideMenu">
            <div
              class="editor__header-slide-menu-option"
              @click="onDuplicateSlide"
            >
              {{ $t("pages.editor.slide.duplicateBtn") }}
            </div>
            <div
              class="editor__header-slide-menu-option"
              @click="showDeleteModal = true"
            >
              {{ $t("pages.editor.slide.deleteBtn") }}
            </div>
          </div>
          <div
            class="editor__header-slide-menu-backdrop"
            v-if="showSlideMenu"
            @click="showSlideMenu = false"
          ></div>
        </div>
        <DropDown
          class="editor__status"
          v-model="slide.status"
          :list="statusOptions"
        >
          <template slot="selected">{{
            $t(`dropdown.${slide.status}`)
          }}</template>
        </DropDown>
        <Button
          theme="secondary"
          class="editor__save-btn"
          :disabled="isUploading"
          @click="checkIfThumbnailIsNeeded"
        >
          {{ $t("pages.editor.saveButton") }}
        </Button>
      </div>
    </div>

    <TabBar
      class="editor__tab-bar"
      :tabs="tabs"
      :active-tab="activeTab"
      @tab-clicked="onTabClicked"
    />

    <div class="editor__footer">
      <div class="editor__footer__container">
        <DropDown
          class="editor__header-orientation"
          v-model="orientation"
          :list="isTemplate ? gridTemplateOptions : gridOptions"
          :direction="'top'"
          :no-border="true"
        >
          <template slot="selected">{{
            $t(`dropdown.${orientation}`)
          }}</template>
        </DropDown>
        <div class="editor__header-play-preview" v-if="showPreviewButton">
          <inline-svg
            class="editor__header-play-preview-icon"
            :src="require('../../assets/icons/Preview.svg')"
            :class="{
              'editor__header-play-preview-icon--paused': isPreviewButtonPaused
            }"
            aria-label="Preview Icon"
            @click="onPreview"
          ></inline-svg>
        </div>
      </div>
    </div>

    <EditorContent
      class="editor__content"
      :url="url"
      :slide="slide"
      @on-image-set="setImage"
      @on-video-set="setVideo"
      :orientation="cssOption"
      v-if="!isTemplate && isWidget === false"
      ref="editorContent"
      @preview-status-change="onPreviewStatusChange"
    ></EditorContent>
    <EditorTemplateContent
      class="editor__content"
      :slide="slide"
      :template="template"
      :orientation="cssOption"
      v-if="isTemplate && template"
      ref="editorTemplateContent"
      @preview-status-change="onPreviewStatusChange"
      @thumbnail-created="onThumbnailCreated"
    ></EditorTemplateContent>

    <EditorSidebar
      :slide="slide"
      :tags="screenTags"
      :slide-tags="slide.tags"
      @open-add-tag-modal="showAddTagModal = true"
      @open-day-modal="showDayModal = true"
      @open-hour-modal="showHourModal = true"
      @open-branch-modal="showBranchModal = true"
      @open-date-modal="showDateModal = true"
    ></EditorSidebar>

    <EditorWebviewBar
      v-if="$route.params.file === 'webview'"
      :show-empty-url-error="showEmptyUrlError"
      :slide="slide"
      :orientation="cssOption"
      @adjusted-url="showEmptyUrlError = false"
    />
    <EditorWallsioBar
      v-if="$route.params.file === 'wallsio'"
      :show-empty-url-error="showEmptyUrlError"
      :slide="slide"
      :orientation="cssOption"
      @adjusted-url="showEmptyUrlError = false"
    />
    <EditorWeatherBar
      v-if="$route.params.file === 'weather'"
      :slide="slide"
      :orientation="cssOption"
    />
    <EditorWidget
      v-if="
        isWidget &&
          $route.params.file !== 'webview' &&
          $route.params.file !== 'wallsio' &&
          $route.params.file !== 'weather'
      "
      :show-empty-url-error="showEmptyUrlError"
      :slide="slide"
      :orientation="cssOption"
      @adjusted-url="showEmptyUrlError = false"
    />
    <AddSlideTagModal
      v-if="showAddTagModal"
      :current-tags="slide.tags"
      @tags-confirmed="onTagsConfirmed"
      @close="closeModal"
    />
    <DayModal
      v-if="showDayModal"
      @close="closeModal"
      :days="slide.days"
    ></DayModal>
    <HourModal
      v-if="showHourModal"
      @close="closeModal"
      :hours="slide.time"
    ></HourModal>
    <BranchModal
      v-if="showBranchModal"
      @close="closeModal"
      :branches="slide.branches"
    ></BranchModal>
    <DateModal
      v-if="showDateModal"
      @confirm="onDatesChosen"
      @close="closeModal"
      :dates="slide.dates"
    ></DateModal>

    <Modal
      @close="closeModal"
      :heading="$t('pages.editor.slide.deleteHeading')"
      v-if="showDeleteModal"
      class="editor__delete-slide-modal"
    >
      <template #modal-content>
        <div class="editor__delete-slide-modal-text">
          {{ $t("pages.editor.slide.deleteWarning") }}
        </div>
        <div class="editor__delete-slide-modal-buttons">
          <Button
            class="editor__delete-slide-modal-button"
            theme="secondary"
            @click="closeModal"
          >
            {{ $t("pages.editor.slide.deleteCancel") }}
          </Button>
          <Button
            class="editor__delete-slide-modal-button"
            theme="warning"
            @click="onDelete"
          >
            {{ $t("pages.editor.slide.deleteBtn") }}
          </Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import EditorSidebar from "./EditorSidebar";
import AddSlideTagModal from "@/components/AddSlideTagModal";
import DayModal from "./DayModal";
import DateModal from "./DateModal";
import HourModal from "./HourModal";
import BranchModal from "./BranchModal";
import Modal from "@/components/Modal";
import EditorContent from "./EditorContent";
import EditorTemplateContent from "./EditorTemplateContent.vue";
import EditorWallsioBar from "./EditorWallsioBar.vue";
import EditorWeatherBar from "./EditorWeatherBar.vue";
import EditorWebviewBar from "./EditorWebviewBar.vue";
import EditorWidget from "./EditorWidget.vue";
import DropDown from "@/components/DropDown";
import Button from "@/components/Button";
import { create_UUID } from "../../utils/utils";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import TabBar from "@/components/TabBar.vue";

export default {
  components: {
    EditorSidebar,
    EditorWebviewBar,
    EditorWallsioBar,
    EditorWeatherBar,
    EditorWidget,
    EditorContent,
    EditorTemplateContent,
    DropDown,
    AddSlideTagModal,
    Modal,
    DayModal,
    HourModal,
    DateModal,
    BranchModal,
    Button,
    TabBar
  },
  data: () => ({
    statusOptions: ["draft", "published", "archived"],
    gridOptions: ["orientation_0", "orientation_1"],
    //gridTemplateOptions: ["orientation_0", "orientation_1", "orientation_2"],
    gridTemplateOptions: ["orientation_0", "orientation_1"],
    orientation: "orientation_0",
    isPreviewButtonPaused: false,
    showSlideMenu: false,
    showDeleteModal: false,
    showEmptyUrlError: false,
    showAddTagModal: false,
    showDayModal: false,
    showDateModal: false,
    showHourModal: false,
    showBranchModal: false,
    thumbnail: null,
    upDatedFile: {
      image: {
        landscape: false,
        portrait: false
      },
      video: {
        landscape: false,
        portrait: false
      }
    },
    oldUrl: null,
    file: {
      image: {
        landscape: {},
        portrait: {}
      },
      video: {
        landscape: {},
        portrait: {}
      }
    },
    url: {
      image: {
        landscape: "",
        portrait: ""
      },
      video: {
        landscape: "",
        portrait: ""
      }
    },
    tempSlide: {
      status: "published",
      duration: 10,
      frequency: 0,
      branches: [],
      tags: [],
      dates: {
        start: "",
        end: ""
      },
      days: [
        { active: true, name: "monday" },
        { active: true, name: "tuesday" },
        { active: true, name: "wednesday" },
        { active: true, name: "thursday" },
        { active: true, name: "friday" },
        { active: true, name: "saturday" },
        { active: true, name: "sunday" }
      ],
      time: [
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true },
        { active: true }
      ]
    },
    slides: null,
    template: null,
    tabs: [{ label: "Content" }, { label: "Slide Settings" }],
    activeTab: 0
  }),
  computed: {
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),
    ...mapGetters("slides", {
      isUploading: "getIsUploading",
      originPage: "getOriginPage",
      widgets: "getWidgetData",
      refreshSlides: "getRefreshSlides"
    }),
    ...mapGetters("templates", {
      tempTemplate: "getTempTemplate"
    }),
    pTags() {
      if (this.parentTeam && this.parentTeam.tags) {
        let keys = Object.keys(this.parentTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.parentTeam.tags[key].isParentTag = true;
          });

          return this.parentTeam.tags;
        }
      }

      return {};
    },
    rTags() {
      if (this.rootTeam && this.rootTeam.tags) {
        let keys = Object.keys(this.rootTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.rootTeam.tags[key].isRootTag = true;
          });

          return this.rootTeam.tags;
        }
      }

      return {};
    },
    screenTags() {
      let tags = {};
      if (this.team.screens) {
        this.team.screens.forEach(screen => {
          tags[screen.id] = {
            name: screen.name,
            icon: screen.orientation
          };
        });
      }

      if (this.team.tags) {
        tags = { ...tags, ...this.team.tags };
      }

      if (Object.keys(this.pTags).length > 0) {
        tags = { ...tags, ...this.pTags };
      }

      if (Object.keys(this.rTags).length > 0) {
        tags = { ...tags, ...this.rTags };
      }

      return tags;
    },
    newSlide() {
      return this.$route.name === "EditorNew";
    },
    children() {
      return {
        ...this.team.children,
        ...(this.team.rootChildren || {})
      };
    },
    slide() {
      if (this.newSlide) {
        return this.tempSlide;
      }

      if (this.slides) {
        let slide = {};
        this.slides.forEach(s => {
          if (s.id === this.$route.params.id) {
            slide = s;
          }
        });

        if (slide.branches && slide.branches.length > 0) {
          slide.branches.forEach(branch => {
            if (this.children[branch.id].avatar) {
              branch.avatar = this.children[branch.id].avatar;
            } else {
              branch.avatar = "";
            }
          });
        }

        if (!slide.tags) {
          slide.tags = [];
        }

        return slide;
      }

      return this.tempSlide;
    },
    branches() {
      if (this.children) {
        return Object.keys(this.children).map(branch => {
          if (this.children[branch].avatar) {
            return {
              name: this.children[branch].name,
              avatar: this.children[branch].avatar,
              id: branch,
              active: true
            };
          } else {
            return {
              name: this.children[branch].name,
              avatar: "",
              id: branch,
              active: true
            };
          }
        });
      }

      return [];
    },
    showPreviewButton() {
      return (
        this.$route.params.file === "template" ||
        this.$route.params.file === "video"
      );
    },
    isTemplate() {
      return this.$route.params.file === "template";
    },
    isWidget() {
      if (this.widgets) {
        let widgetNames = ["webview", "wallsio"];
        widgetNames = widgetNames.concat(Object.keys(this.widgets));
        return widgetNames.includes(this.$route.params.file);
      }

      return undefined;
    },
    cssOption() {
      if (this.orientation === "orientation_0") {
        return "0";
      } else if (this.orientation === "orientation_1") {
        return "1";
      } else {
        return "2";
      }
    },
    activeTabClass() {
      return `editor__tab-${this.activeTab}`;
    },
    orientationClass() {
      return this.orientation === "orientation_1" ? "editor__portrait" : "";
    }
  },
  watch: {
    refreshSlides() {
      if (this.refreshSlides) {
        this.slides = this.team.slides ? _.cloneDeep(this.team.slides) : [];
        this.slides = this.slides.filter(item => item !== undefined);
        this.onRefreshDone();

        if (this.isTemplate && !this.template) {
          this.getTemplate();
        }
      }
    },
    $route() {
      this.slides = this.team.slides ? _.cloneDeep(this.team.slides) : [];
      this.slides = this.slides.filter(item => item !== undefined);

      if (this.isTemplate && !this.template) {
        this.getTemplate();
      }
    },
    team: {
      handler() {
        if (!this.slides || this.team.slides.length !== this.slides.length) {
          this.slides = this.team.slides ? _.cloneDeep(this.team.slides) : [];
          this.slides = this.slides.filter(item => item !== undefined);
        }

        this.orientation = this.team.slideOrientation
          ? this.team.slideOrientation
          : "orientation_0";

        if (this.isTemplate && !this.template) {
          this.getTemplate();
        }
      },
      deep: true
    },
    slide: {
      handler() {
        if (!this.newSlide) {
          this.url = this.slide.url;
          if (
            !this.oldUrl &&
            !this.isWidget &&
            !this.isTemplate &&
            this.slide?.url
          ) {
            this.oldUrl = JSON.parse(JSON.stringify(this.slide.url));
          }
        }
      },
      deep: true
    },
    branches() {
      this.tempSlide.branches = this.branches;
    },
    isUploading() {
      if (!this.isUploading) {
        this.url = this.slide?.url;

        if (!this.isTemplate && !this.isWidget) {
          this.oldUrl = JSON.parse(JSON.stringify(this.slide?.url));
          this.upDatedFile = {
            image: {
              landscape: false,
              portrait: false
            },
            video: {
              landscape: false,
              portrait: false
            }
          };
        }
      }
    }
  },
  mounted() {
    this.fetchWidgetData();

    if (!this.slides) {
      this.slides = this.team.slides ? _.cloneDeep(this.team.slides) : [];
      this.slides = this.slides.filter(item => item !== undefined);
    }

    this.orientation = this.team.slideOrientation
      ? this.team.slideOrientation
      : "orientation_0";

    if (!this.newSlide) {
      if (!this.isWidget && !this.isTemplate) {
        if (this.slide.url) {
          this.url = this.slide.url;

          this.oldUrl = JSON.parse(JSON.stringify(this.slide.url));
        }
      }
    } else {
      if (this.children) {
        this.tempSlide.branches = this.branches;
      }
    }

    if (this.isTemplate && !this.template) {
      this.getTemplate();
    }
  },
  methods: {
    ...mapActions("slides", [
      "uploadSlide",
      "deleteSlide",
      "updateSlide",
      "saveTemplateSlide",
      "updateTemplateSlide",
      "saveWebviewSlide",
      "updateWebviewSlide",
      "saveWidgetSlide",
      "updateWidgetSlide",
      "duplicateSlide",
      "setIsUploading",
      "setRefreshSlides",
      "fetchWidgetData"
    ]),
    ...mapActions("teams", ["updateTeam", "fetchCurrentTeam"]),
    ...mapActions("notifications", ["addNotification"]),
    ...mapActions("templates", [
      "resetTemplate",
      "fetchSingleTemplate",
      "setTempTemplate"
    ]),
    onTabClicked(input) {
      this.activeTab = input;
    },
    onRefreshDone() {
      this.setRefreshSlides(false);
    },
    onDatesChosen(dates) {
      this.slide.dates = dates;
      this.closeModal();
    },
    getTemplate() {
      this.resetTemplate();
      let teamId = "";
      if (this.$route.params.isParent === "1") {
        teamId = this.team.parentTeam;
      } else if (this.$route.params.isParent === "2") {
        teamId = this.team.rootTeam;
      } else if (this.$route.params.isParent === "3") {
        teamId = "-NJMS5tyw2TTSzSv9eMN";
      } else {
        teamId = this.team.id;
      }

      if (this.team.id) {
        this.fetchSingleTemplate({
          teamId: teamId,
          templateId: this.$route.params.templateId
        }).then(template => {
          if (!this.template) {
            this.template = _.cloneDeep(template);
            if (!this.newSlide) {
              this.tempTemplate.elements = this.tempTemplate.elements.map(
                element => {
                  if (this.slide?.template?.elements) {
                    let slideElement = this.slide.template.elements.filter(
                      e => e.id === element.id
                    )[0];

                    return this.mergeElements(element, slideElement);
                  }
                }
              );
              this.setTempTemplate(this.tempTemplate);
            }
          }
        });
      }
    },
    mergeElements(obj1, obj2) {
      if (obj1.type === "stack" && obj2.type === "stack") {
        const mergedChildren = (obj1.children || []).map((child1, index) => {
          const child2 = obj2.children && obj2.children[index];
          if (child2) {
            return this.mergeElements(child1, child2);
          }
          return child1;
        });

        return {
          ...obj1,
          ...obj2,
          children: mergedChildren
        };
      } else {
        return {
          ...obj1,
          ...obj2,
          css: obj1.css
        };
      }
    },
    onPreview() {
      if (!this.isPreviewButtonPaused) {
        if (this.isTemplate) {
          this.$refs.editorTemplateContent.onPlay();
        } else {
          this.$refs.editorContent.onPlay();
        }
      }
    },
    onPreviewStatusChange(status) {
      this.isPreviewButtonPaused = status;
    },
    onDelete() {
      this.deleteSlide(this.$route.params.id)
        .then(() => {
          this.onClose();
          this.showNotification("slideDeletion", "success");
        })
        .catch(() => {
          this.showNotification("error", "error");
        });
    },
    onClose() {
      this.closeModal();
      if (this.$route.params.file === "template") {
        this.resetTemplate();
      }
      this.$router.push(this.originPage);
      this.fetchCurrentTeam(this.team.id);
    },
    showNotification(title, type) {
      this.addNotification({
        title: title,
        type: type,
        autoRemove: true
      });
    },
    onTagsConfirmed(tags) {
      this.slide.tags = tags;
      this.closeModal();
    },
    setImage(payload) {
      if (this.cssOption === "0") {
        this.file.image.landscape = payload.image;
        this.url.image.landscape = payload.image.src;
        if (!this.newSlide) {
          this.upDatedFile.image.landscape = true;
        }
      } else {
        this.file.image.portrait = payload.image;
        this.url.image.portrait = payload.image.src;
        if (!this.newSlide) {
          this.upDatedFile.image.portrait = true;
        }
      }
    },
    setVideo(payload) {
      if (this.cssOption === "0") {
        this.slide.duration = payload.duration;
        this.file.video.landscape = payload.video;
        this.url.video.landscape = payload.url;
        if (!this.newSlide) {
          this.upDatedFile.video.landscape = true;
        }
      } else {
        this.slide.duration = payload.duration;
        this.file.video.portrait = payload.video;
        this.url.video.portrait = payload.url;
        if (!this.newSlide) {
          this.upDatedFile.video.portrait = true;
        }
      }
    },
    checkIfThumbnailIsNeeded() {
      this.setIsUploading(true);

      if (!this.isTemplate) {
        this.onSave();
      } else {
        if (this.newSlide) {
          setTimeout(() => {
            // otherwise the setIsUploading is somehow triggerd after the thumbnail creation
            this.$refs.editorTemplateContent.createThumbnail();
          }, 50);
        } else if (!_.isEqual(this.template, this.tempTemplate)) {
          setTimeout(() => {
            // otherwise the setIsUploading is somehow triggerd after the thumbnail creation
            this.$refs.editorTemplateContent.createThumbnail();
          }, 50);
        } else {
          this.onSave();
        }
      }
    },
    onThumbnailCreated(payload) {
      this.thumbnail = payload;
      this.onSave();
    },
    createQRImage() {
      this.template.elements.map((element, index) => {
        if (
          element.type === "qr" &&
          this.tempTemplate.elements[index].url !== element.url
        ) {
          let qrDiv = document.getElementById(`qr_${index}`);
          const canvas = qrDiv.querySelector("canvas");
          element.image.src = canvas.toDataURL("image/png");
        }
      });
    },
    onSave() {
      if (this.isTemplate) {
        this.createQRImage();

        if (this.newSlide) {
          this.onSaveTemplate();
        } else {
          this.onUpdateTemplate();
        }
      } else if (this.isWidget) {
        if (this.slide.url && this.slide.url.landscape) {
          if (this.newSlide) {
            this.onSaveWidget();
          } else {
            this.onUpdateWidget();
          }
        } else {
          this.showEmptyUrlError = true;
          this.setIsUploading(false);
        }
      } else {
        if (this.newSlide) {
          this.uploadNew();
        } else {
          this.onUpdateSlide();
        }
      }
    },
    uploadNew() {
      const fileType = this.$route.params.file === "video" ? "video" : "image";
      if (
        Object.keys(this.file[fileType].landscape).length !== 0 ||
        Object.keys(this.file[fileType].portrait).length !== 0
      ) {
        this.uploadSlide({
          data: {
            ...this.tempSlide,
            type: fileType,
            id: create_UUID()
          },
          file: this.file
        });
      } else {
        this.showNotification("error", "error");
      }
    },
    onSaveWidget() {
      this.saveWidgetSlide({
        ...this.slide,
        type: this.$route.params.file,
        id: create_UUID()
      });
    },
    onUpdateWidget() {
      this.updateWidgetSlide(this.slide);
    },
    onSaveTemplate() {
      let teamId = null;
      if (this.$route.params.isParent === "1") {
        teamId = this.team.parentTeam;
      } else if (this.$route.params.isParent === "2") {
        teamId = this.team.rootTeam;
      } else {
        teamId = this.team.id;
      }

      this.saveTemplateSlide({
        createdAt: new Date(),
        template: {
          ...this.template,
          isParentTemplate: this.$route.params.isParent === "1" ? true : false,
          isRootTemplate: this.$route.params.isParent === "2" ? true : false,
          isLobbySpaceTemplate:
            this.$route.params.isParent === "3" ? true : false,
          teamId: teamId
        },
        tempTemplate: this.tempTemplate,
        slide: {
          ...this.slide,
          type: this.$route.params.file,
          id: create_UUID()
        },
        thumbnail: this.thumbnail
      });
    },
    onUpdateTemplate() {
      let teamId = null;
      if (this.$route.params.isParent === "1") {
        teamId = this.team.parentTeam;
      } else if (this.$route.params.isParent === "2") {
        teamId = this.team.rootTeam;
      } else {
        teamId = this.team.id;
      }

      this.updateTemplateSlide({
        template: {
          ...this.template,
          isParentTemplate: this.$route.params.isParent === "1" ? true : false,
          isRootTemplate: this.$route.params.isParent === "2" ? true : false,
          isLobbySpaceTemplate:
            this.$route.params.isParent === "3" ? true : false,
          teamId: teamId
        },
        tempTemplate: this.tempTemplate,
        slide: this.slide,
        thumbnail: this.thumbnail
      });
    },
    onUpdateSlide() {
      if (
        !this.upDatedFile[this.$route.params.file].landscape &&
        !this.upDatedFile[this.$route.params.file].portrait
      ) {
        this.slides.forEach(slide => {
          if (slide.id === this.slide.id) {
            slide.lastChangedDate = new Date();
            slide.editedBy = this.user.user_id;
          }
        });
        this.updateTeam({
          ...this.team,
          slides: this.slides
        }).then(() => {
          this.showNotification("slideUpdated", "success");
          this.setIsUploading(false);
        });
      } else {
        this.updateFileSlide();
      }
    },
    updateFileSlide() {
      let payload = {
        data: {
          ...this.slide,
          type: this.$route.params.file
        },
        file: this.file,
        id: this.$route.params.id,
        updated: this.upDatedFile,
        oldUrl: this.oldUrl
      };

      this.updateSlide(payload);
    },
    onDuplicateSlide() {
      this.showSlideMenu = false;
      this.duplicateSlide({
        ...this.slide,
        id: create_UUID(),
        title: this.slide.title + " Copy"
      });
    },
    closeModal() {
      this.showAddTagModal = false;
      this.showDayModal = false;
      this.showHourModal = false;
      this.showBranchModal = false;
      this.showDateModal = false;
      this.showDeleteModal = false;
    }
  }
};
</script>

<style lang="scss">
.editor {
  min-height: 100vh;
  &__tab-bar {
    position: fixed;
    top: 53px;
    background: white;
    width: 100%;
    justify-content: space-around;
    display: none !important;
    z-index: 5;
  }
  &__header {
    @apply flex justify-between px-4 py-2 border-b border-ls-gray-200 bg-white fixed;
    width: 100%;
    z-index: 10;

    &--right {
      display: flex;
    }

    &-play-preview {
      @apply flex items-center mr-2;

      &-icon {
        width: 36px;
        height: 36px;
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;

        &--paused {
          @apply cursor-default;

          circle {
            stroke: #a8a8aa;
          }

          path {
            fill: #a8a8aa;
          }
        }

        &:hover {
          background-color: #efefef;
        }
      }
    }
    &-orientation {
      @apply mr-1 text-xs font-semibold;
    }

    &-slide-menu {
      @apply flex items-center mr-3 relative;

      &:hover {
        @apply bg-ls-gray-200;
        border-radius: 4px;
      }

      &-icon {
        width: 36px;
        height: 36px;
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;
      }

      &-wrapper {
        @apply absolute bg-white  rounded shadow-md z-10 border border-ls-gray-100;
        right: 0;
        top: 32px;
        white-space: nowrap;
      }

      &-option {
        @apply text-xs font-semibold text-ls-gray-800 py-2 px-3 cursor-pointer;

        &:hover {
          @apply bg-ls-gray-100;
        }
      }

      &-backdrop {
        @apply fixed top-0 left-0 w-full h-full;
        z-index: 9;
        cursor: default;
      }
    }
  }

  &__footer {
    @apply flex justify-center fixed;
    bottom: 16px;
    width: 100%;
    z-index: 1;
    &__container {
      @apply bg-white flex border border-ls-gray-200 rounded-lg px-3 py-1;
      .dropdown__selected-wrapper {
        font-weight: 400;
      }
    }
  }
  &__status {
    @apply text-xs font-semibold;
  }

  &__close-btn {
    display: block;
    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__close-btn--mobile {
    display: none !important;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-2;
    font-size: 14px;
    line-height: 20px;
    width: 640px;
  }

  &__save-btn {
    margin-left: 0.5rem;

    &--active {
      @apply bg-ls-purple;
    }
  }

  &__delete-btn {
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    @apply text-ls-red text-right;
  }

  &__delete-slide-modal-text {
    @apply mb-4;
  }

  &__delete-slide-modal-buttons {
    @apply flex;
  }

  &__delete-slide-modal-button {
    width: 100%;

    &:first-child {
      @apply mr-4;
    }
  }
}

@media screen and (max-width: 768px) {
  .editor {
    &__tab-bar {
      display: flex !important;
    }

    &__content {
      width: 100vw;
      display: inline;
    }

    &-template-content {
      display: flex;
    }

    &__close-btn {
      display: none !important;
    }

    &__close-btn--mobile {
      display: block !important;
      .btn__icon {
        margin: 0;
      }
    }

    &__status {
      display: none;
    }

    .editor-element-bar,
    .editor-widget-bar,
    .editor-weather-bar,
    .editor-walls-io-bar,
    .editor-webview-bar,
    .side-bar {
      width: 100%;
    }

    .editor-walls-io-bar,
    .editor-webview-bar {
      top: 432px !important;
    }

    .editor-element-bar__generateContent {
      width: 100%;
      position: relative;
      padding: 16px 0;
    }
    .editor-template-content__preview,
    .editor-widget-content,
    .editor-weather-content,
    .editor-walls-io-content,
    .editor-webview-content {
      transform: scale(0.18) !important;
      top: 131px;
      transform-origin: 50% 0;
      position: absolute;
    }

    .editor-widget-content__iframe-wrapper,
    .editor-weather-content__iframe-wrapper,
    .editor-webview-content__iframe-wrapper,
    .editor-walls-io-content__iframe-wrapper {
      margin-top: 388px;
      width: 1920px;
      height: 1080px;
      display: flex;
      justify-content: center;
    }

    .editor-widget-content__iframe,
    .editor-weather-content__iframe,
    .editor-webview-content__iframe,
    .editor-walls-io-content__iframe {
      transform: scale(1);
    }

    &__portrait {
      .editor-element-bar,
      .editor-widget-bar,
      .editor-weather-bar {
        display: block;
        top: 438px !important;
        position: relative;
      }

      .editor-walls-io-bar,
      .editor-weather-bar,
      .editor-webview-bar,
      .editor-widget-bar {
        top: 502px !important;
      }

      .editor__footer {
        top: 488px !important;
      }
    }

    &__tab-0 {
      .editor-element-bar,
      .editor-widget-bar,
      .editor-walls-io-bar,
      .editor-weather-bar,
      .editor-webview-bar {
        display: block;
        top: 352px;
        position: relative;
      }

      .side-bar {
        display: none;
      }
    }

    &__tab-1 {
      .editor-element-bar,
      .editor-widget-bar,
      .editor-weather-bar,
      .editor-webview-bar,
      .editor-walls-io-bar,
      .editor-widget-content,
      .editor-weather-content,
      .editor-webview-content,
      .editor-walls-io-content {
        display: none;
      }

      .side-bar {
        display: block;
        top: 108px;
      }
    }

    .editor__input {
      width: 100%;
    }

    .side-bar__slide-infos {
      width: 100%;
      position: relative;
      margin-top: 16px;
      padding: 24px 0;
    }

    &__footer {
      bottom: auto;
      top: 342px;
      position: relative;
    }
  }
}
</style>
