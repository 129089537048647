<template>
  <div class="branch-option">
    <div class="branch-option__left">
      <div
        class="branch-option__logo"
        v-if="branch.avatar"
        :style="{
          'background-image': 'url(' + branch.avatar + ')'
        }"
      ></div>
      <ReplacementLogo
        :name="branch.name"
        class="branch-option__logo--unset"
        v-else
      />
      <div class="branch-option__name">{{ branch.name }}</div>
    </div>
    <Toggle class="branch-option__toggle" v-model="branch.active"></Toggle>
  </div>
</template>

<script>
import Toggle from "@/components/Toggle";
import ReplacementLogo from "@/components/ReplacementLogo.vue";

export default {
  components: {
    Toggle,
    ReplacementLogo
  },
  props: {
    branch: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.branch-option {
  @apply flex justify-between items-center w-full;

  &__left {
    @apply flex items-center;
  }

  &__name {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  &__logo {
    @apply bg-cover w-6 h-6 mr-2 rounded-lg;

    &--unset {
      @apply w-6 h-6 mr-2 rounded-lg;
    }
  }
}
</style>
