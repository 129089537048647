<template>
  <div class="day-modal">
    <Modal
      :show-close-button="false"
      @close="onClose"
      :heading="$t('pages.editor.days.title')"
    >
      <div class="day-modal__content" slot="modal-content">
        <div class="day-modal__list">
          <div
            class="day-modal__option"
            :class="{ 'day-modal__option--active': day.active }"
            v-for="(day, index) in days"
            :key="index"
            @click="day.active = !day.active"
          >
            {{ $t(`pages.editor.days.${day.name}`) }}
          </div>
        </div>
        <Button class="day-modal__update-btn" @click="onClose">
          {{ $t("pages.editor.days.update") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";

export default {
  components: {
    Modal,
    Button
  },
  props: {
    days: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({}),
  methods: {
    onClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.day-modal {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  &__option {
    font-size: 14px;
    width: 70px;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;

    &--active {
      background-color: #000;
      border-radius: 8px;
      color: #fff;
    }
  }

  &__update-btn {
    @apply mt-4;
  }
}
</style>
