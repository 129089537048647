<template>
  <div class="editor-walls-io">
    <div class="editor-walls-io-bar">
      <div class="editor-walls-io-bar__element">
        <div class="editor-walls-io-bar__element-label">
          URL
        </div>
        <input class="editor-walls-io-bar__input" v-model="url" />
        <Button
          :disabled="disableSaveButton"
          class="editor-walls-io-bar__button"
          @click="setWebviewUrl"
        >
          {{ $t("pages.editor.wallsio.addUrl") }}
        </Button>
        <div class="editor-walls-io-bar__feedback">
          {{ feedback }}
        </div>
        <div class="editor-walls-io-bar__feedback" v-if="showEmptyUrlError">
          {{ $t("pages.editor.wallsio.feedback.empty") }}
        </div>
        <div class="editor-walls-io-bar__options">
          <div class="editor-walls-io-bar__element-label">
            {{ $t("pages.editor.wallsio.theme") }}
          </div>
          <div class="editor-walls-io-bar__option">
            <DropDown
              class="editor-walls-io-bar__theme-dropdown"
              :list="themeOptions"
              v-model="theme"
              :translation="false"
            >
              <template slot="selected">
                <div class="editor-walls-io-bar__theme-option">
                  {{ theme }}
                </div>
              </template>
            </DropDown>
          </div>
          <div class="editor-walls-io-bar__option">
            {{ $t("pages.editor.wallsio.noBackground") }}
            <Toggle v-model="nobackground" />
          </div>
          <div class="editor-walls-io-bar__option">
            {{ $t("pages.editor.wallsio.showHeader") }}
            <Toggle v-model="show_header" />
          </div>

          <div
            class="editor-walls-io-bar__sign-up"
            v-html="$t('pages.editor.wallsio.noAccountYet')"
          />
        </div>
      </div>
    </div>
    <div class="editor-walls-io-content" v-if="slide.url">
      <div v-if="showUrlPreview">
        <div
          class="editor-walls-io-content__iframe-wrapper editor-walls-io-content__iframe-wrapper--horizontal"
          v-if="orientation === '0'"
        >
          <iframe
            ref="iFrame"
            class="editor-walls-io-content__iframe editor-walls-io-content__iframe--horizontal"
            :src="slide.url.landscape"
          />
        </div>
        <div
          class="editor-walls-io-content__iframe-wrapper editor-walls-io-content__iframe-wrapper--vertical"
          v-if="orientation === '1'"
        >
          <iframe
            ref="iFrame"
            class="editor-walls-io-content__iframe editor-walls-io-content__iframe--vertical"
            :src="slide.url.landscape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import DropDown from "../../components/DropDown.vue";
import Toggle from "@/components/Toggle";
import isURL from "validator/es/lib/isURL";

export default {
  components: {
    Button,
    DropDown,
    Toggle
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    showEmptyUrlError: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    url: "",
    feedback: "",
    disableSaveButton: false,
    showUrlPreview: false,
    themeOptions: ["kiosk", "fluid", "map", "billboard", "map", "bricks"],
    theme: "kiosk",
    nobackground: false,
    show_header: false
  }),
  watch: {
    url() {
      if (this.slide.url) {
        this.disableSaveButton = this.slide.url.landscape === this.url;
      }
    },
    slide: {
      handler() {
        if (this.slide.url) {
          this.url = this.slide.url.landscape;
          this.checkUrl();
          this.extractUrlParams();
        }
      },
      deep: true
    },
    theme() {
      this.updateUrl();
    },
    nobackground() {
      this.updateUrl();
    },
    show_header() {
      this.updateUrl();
    }
  },
  mounted() {
    if (this.slide.url) {
      this.url = this.slide.url.landscape;
      this.checkUrl();
      this.extractUrlParams();
    }
  },
  methods: {
    extractUrlParams() {
      let tempUrl = new URL(this.url);
      let params = tempUrl.searchParams;

      params.forEach((value, key) => {
        if (key === "theme") {
          this.theme = value;
        }

        if (key === "nobackground") {
          this.nobackground = value === "1" ? true : false;
        }

        if (key === "show_header") {
          this.show_header = value === "1" ? true : false;
        }
      });
    },
    checkUrl() {
      let tempUrl = new URL(this.url);
      this.showUrlPreview = tempUrl.hostname.endsWith("walls.io");
    },
    updateUrl() {
      if (!this.url) {
        return;
      }

      let url = new URL(this.url);

      if (this.nobackground) {
        url.searchParams.set("nobackground", "1");
      } else {
        url.searchParams.set("nobackground", "0");
      }

      if (this.show_header) {
        url.searchParams.set("show_header", "1");
      } else {
        url.searchParams.set("show_header", "0");
      }

      url.searchParams.set("theme", this.theme);

      this.url = url.toString();
      this.setWebviewUrl();
    },
    async setWebviewUrl() {
      this.checkUrl();

      let options = {
        require_protocol: true,
        protocols: ["https"]
      };

      if (!isURL(this.url, options) || !this.showUrlPreview) {
        this.feedback = this.$t("pages.editor.wallsio.feedback.invalidUrl");
        return;
      }

      this.$emit("adjusted-url");

      this.feedback = "";
      if (this.slide.url) {
        this.slide.url.landscape = this.url;
        this.slide.url.portrait = this.url;
        this.disableSaveButton = true;
      } else {
        let url = {
          landscape: this.url,
          portrait: this.url
        };

        this.slide.url = url;
        this.disableSaveButton = true;
      }
    }
  }
};
</script>

<style lang="scss">
.editor-walls-io-bar {
  position: fixed;
  left: 0;
  top: 53px;
  padding: 16px 12px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;

  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full;
    font-size: 14px;
    line-height: 20px;
  }

  &__option {
    @apply flex justify-between mb-3 text-sm w-full;
  }

  &__theme-dropdown {
    @apply w-full;
  }

  &__button {
    @apply w-full my-3;
  }

  &__feedback {
    @apply my-2 text-ls-red text-sm;
  }

  &__sign-up {
    @apply mt-6 text-sm;

    a {
      @apply underline text-ls-purple font-semibold;
    }
  }
}

.editor-walls-io-content {
  height: calc(100vh - 53px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__iframe-wrapper--horizontal {
    width: 768px;
    height: 432px;
  }

  &__iframe-wrapper--vertical {
    width: 324px;
    height: 576px;
  }

  &__iframe {
    &--horizontal {
      width: 1920px;
      height: 1080px;
      transform: scale(0.4);
      transform-origin: 0 0;
    }

    &--vertical {
      width: 1080px;
      height: 1920px;
      transform: scale(0.3);
      transform-origin: 0 0;
    }
  }
}
</style>
