export const allowedUrls = [
  "meteoblue.com",
  "feed.mikle.com",
  "tagesschau.de",
  "api.wo-cloud.com",
  "elfsight.com",
  "lobbyspace.me",
  "ipcamlive.com",
  "webplayer.nau.ch",
  "ooh.dsx4u.de",
  "walls.io",
  "screenplayer.20min.ch",
  "firebasestorage.googleapis.com",
  "sharepoint.com",
  "docs.google.com",
  "b13meqd.myrdbx.io"
];
