<template>
  <div class="toggle" :class="classes" @click="toggle">
    <div class="toggle__button"></div>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        "toggle--on": this.value,
        "toggle--off": !this.value,
        "toggle--disabled": this.disabled
      };
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;

      this.$emit("input", !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  width: 38px;
  cursor: pointer;
  @apply rounded-full p-1 inline-flex flex-col items-center h-6;

  &__button {
    @apply bg-white rounded-full w-4 h-4 box-content;
  }

  &--on {
    @apply bg-ls-green items-end;
    &:hover{
      background: #8DC319;
    }
  }

  &--off {
    @apply bg-ls-gray-400 items-start;
    &:hover{
      @apply bg-ls-gray-600;
    }
    .toggle__button {
      @apply shadow;
    }
  }

  &--disabled {
    position: relative;
    ::after {
      @apply rounded-full absolute top-0 left-0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.5);
    }
  }
}
</style>
