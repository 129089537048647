<template>
  <div class="stack-text-area">
    <div
      class="stack-text-area__child"
      v-for="(stack, index) in element.children"
      :key="index"
    >
      <div v-if="stack.type === 'stack'">
        <StackTextArea :element="stack" :style="stack.css" />
      </div>
      <div
        class="editor-element-bar__element-label"
        v-if="stack.type === 'text'"
      >
        {{ stack.name }}
      </div>
      <textarea-autosize
        class="stack-text-area__text"
        :value="getText(stack.text)"
        @input="updateStackText($event, index)"
        v-if="stack.type === 'text'"
      >
      </textarea-autosize>
    </div>
  </div>
</template>

<script>
export default {
  name: "StackTextArea",
  props: {
    element: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getText(text) {
      let element = text;
      element = element.replace(/<div>/g, "\n");
      element = element.replace(/<br>/g, "\n");
      element = element.replace(/&nbsp;/g, " ");
      element = element.replace(/<\/div>/g, "");
      return element;
    },
    updateStackText(e, index) {
      this.element.children[index].text = e;
    }
  }
};
</script>

<style lang="scss">
.stack-text-area {
  &__text {
    @apply bg-ls-gray-100 rounded-lg p-2 mb-4 text-black;
    width: 100% !important;
    resize: none;
  }
}
</style>
