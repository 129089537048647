<template>
  <div
    class="tag"
    :class="{
      'tag--active': tag.chosen || active,
      'tag--parent': tag.isParentTag,
      'tag--root': tag.isRootTag,
      'tag--deleted': tag.deleted
    }"
    v-if="tag"
  >
    <template v-if="showIcon">
      <inline-svg
        class="tag__icon"
        :src="require(`../assets/icons/Portrait small.svg`)"
        aria-label="Tag Icon"
        v-if="tag.icon === 'portrait'"
      ></inline-svg>
      <inline-svg
        class="tag__icon"
        :src="require(`../assets/icons/Landscape small.svg`)"
        aria-label="Tag Icon"
        v-else-if="tag.icon === 'landscape'"
      ></inline-svg>
      <inline-svg
        class="tag__icon"
        :src="require(`../assets/icons/Tag small.svg`)"
        aria-label="Tag Icon"
        v-else
      ></inline-svg>
    </template>
    {{ tag.name }}
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    deleted: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.tag {
  @apply cursor-pointer border-ls-gray-300 border text-ls-gray-800 py-1 px-2 mr-2 rounded-full text-xs inline-flex items-center;
  font-weight: 600;

  &__icon {
    width: 12px;
    height: 12px;
    @apply mr-2;

    path,
    rect {
      fill: #e1e1e2;
    }
  }
  &--active {
    @apply border-ls-gray-400 text-black bg-ls-gray-100;
    .tag__icon {
      path,
      rect {
        fill: rgb(126, 147, 255);
      }
    }
  }

  &--deleted {
    @apply border-ls-gray-200 text-gray-400 cursor-default;

    .tag__icon {
      path,
      rect {
        fill: #d0d0d2;
      }
    }
  }
}

.tag--active.tag--parent {
  .tag__icon {
    path,
    rect {
      fill: #25d6e1;
    }
  }
}

.tag--active.tag--root {
  .tag__icon {
    path,
    rect {
      fill: #99d41b;
    }
  }
}
</style>
