<template>
  <Modal
    class="add-slide-tag-modal"
    @close="onClose"
    :heading="$t('components.addSlideTag.heading')"
  >
    <template #modal-content>
      <div class="add-slide-tag-modal__form-field">
        <input
          :placeholder="$t('components.addSlideTag.find')"
          class="add-slide-tag-modal__input"
          v-model="searchTag"
        />
      </div>

      <div class="add-slide-tag-modal__form-field" v-if="tags.length > 0">
        <div class="add-slide-tag-modal__tags">
          <Tag
            class="add-slide-tag-modal__tag"
            :tag="tag"
            v-for="(tag, index) in filteredTags"
            :key="index"
            @click.native="tag.chosen = !tag.chosen"
          />
        </div>
      </div>

      <div class="add-slide-tag-modal__button-wrapper">
        <div class="add-slide-tag-modal__selection-buttons">
          <Button
            class="add-slide-tag-modal__select-btn"
            @click="selectAll"
            theme="secondary"
          >
            {{ $t("components.addSlideTag.selectAll") }}
          </Button>
          <Button
            class="add-slide-tag-modal__deselect-btn"
            @click="deselectAll"
            theme="secondary"
          >
            {{ $t("components.addSlideTag.deselectAll") }}
          </Button>
        </div>
        <Button class="add-slide-tag-modal__confirm-btn" @click="onConfirm">
          {{ $t("components.addSlideTag.confirmBtn") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "./Button.vue";
import Tag from "./Tag.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    Button,
    Tag
  },
  props: {
    currentTags: {
      type: Array,
      requiered: true,
      default: () => []
    }
  },
  data: () => ({
    searchTag: "",
    tag: "",
    tags: [],
    newTags: []
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      rootTeam: "getRootTeam"
    }),
    screens() {
      return this.team.screens;
    },
    pTags() {
      if (this.parentTeam && this.parentTeam.tags) {
        let keys = Object.keys(this.parentTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.parentTeam.tags[key].isParentTag = true;
          });

          return this.parentTeam.tags;
        }
      }

      return {};
    },
    rTags() {
      if (this.rootTeam && this.rootTeam.tags) {
        let keys = Object.keys(this.rootTeam.tags);

        if (keys.length > 0) {
          keys.forEach(key => {
            this.rootTeam.tags[key].isRootTag = true;
          });

          return this.rootTeam.tags;
        }
      }

      return {};
    },
    filteredTags() {
      if (this.searchTag.length !== "") {
        return this.tags.filter(tag => {
          return tag.name.toLowerCase().includes(this.searchTag.toLowerCase());
        });
      }

      return this.tags;
    }
  },
  mounted() {
    this.createTags();
  },
  methods: {
    createTags() {
      let tempTags = [];
      let nameTags = this.screens.map(screen => {
        return {
          id: String(screen.id),
          name: screen.name,
          icon: screen.orientation
        };
      });

      if (this.team.tags) {
        let teamTags = Object.entries(this.team.tags).map(([id, value]) => ({
          id,
          name: value.name,
          icon: "normal"
        }));
        tempTags = nameTags.concat(teamTags);
      } else {
        tempTags = nameTags;
      }

      if (this.team.parentTeam) {
        let parentTags = Object.entries(this.pTags).map(([id, value]) => ({
          id,
          name: value.name,
          isParentTag: value.isParentTag,
          icon: "normal"
        }));

        parentTags.forEach(tag => {
          tempTags.push(tag);
        });
      }

      if (this.team.rootTeam && this.team.rootTeam !== this.team.parentTeam) {
        let rootTags = Object.entries(this.rTags).map(([id, value]) => ({
          id,
          name: value.name,
          isRootTag: value.isRootTag,
          icon: "normal"
        }));

        rootTags.forEach(tag => {
          tempTags.push(tag);
        });
      }

      let tags = tempTags.map(tag => {
        if (this.currentTags.some(elem => elem.id === tag.id)) {
          return {
            id: tag.id,
            name: tag.name,
            chosen: true,
            isParentTag: tag.isParentTag ? true : false,
            isRootTag: tag.isRootTag ? true : false,
            icon: tag.icon
          };
        } else {
          return {
            id: tag.id,
            name: tag.name,
            chosen: false,
            isParentTag: tag.isParentTag ? true : false,
            isRootTag: tag.isRootTag ? true : false,
            icon: tag.icon
          };
        }
      });

      this.tags = tags;
    },
    onClose() {
      this.$emit("close");
    },
    selectAll() {
      this.tags = this.tags.map(tag => {
        return {
          id: tag.id,
          icon: tag.icon,
          name: tag.name,
          chosen: true,
          isParentTag: tag.isParentTag,
          isRootTag: tag.isRootTag
        };
      });
    },
    deselectAll() {
      this.tags = this.tags.map(tag => {
        return {
          id: tag.id,
          icon: tag.icon,
          name: tag.name,
          chosen: false,
          isParentTag: tag.isParentTag,
          isRootTag: tag.isRootTag
        };
      });
    },
    onConfirm() {
      let chosenTags = this.tags.filter(tag => {
        return tag.chosen;
      });

      let tags = chosenTags.map(tag => {
        return {
          id: tag.id,
          isParentTag: tag.isParentTag,
          isRootTag: tag.isRootTag
        };
      });

      this.$emit("tags-confirmed", tags);
    }
  }
};
</script>

<style lang="scss">
.add-slide-tag-modal {
  .modal__content {
    max-width: 600px;
    overflow: auto;
  }

  &__btn {
    @apply mt-6 w-full;
  }

  &__input {
    @apply bg-ls-gray-100 mt-4 rounded-lg w-full;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
  }

  &__tag {
    @apply mt-2;
  }

  &__button-wrapper {
    @apply flex justify-between mt-5;
  }

  &__selection-buttons {
    @apply flex;
  }

  &__select-btn {
    @apply mr-2;
  }

  &__confirm-btn {
    width: 80px;
  }
}
</style>
